import React from 'react';
import Img from 'gatsby-image';
import bg from '../../images/PAV_banner_backgroud.png';
import bg2 from '../../images/PAV_banner_backgroud-2.png';
import downAppStoreSvg from '../../images/icons/down_app_store.svg';
import downCHPlaySvg from '../../images/icons/down_app_chplay.svg';
import { IndexBannerStyles } from './styles';
import { Grid } from 'antd';

interface IndexBannerProps {
  backgroundImage2: any;
  backgroundImage: any;
}

const IndexBanner: FC<IndexBannerProps> = ({
  backgroundImage,
  backgroundImage2,
}) => {
  const screens = Grid.useBreakpoint();
  return (
    <IndexBannerStyles id="banner">
      {/* {!screens.xs && <img src={bg} alt="" className="background-image" />}
      {!screens.xs && (
        <Img
          objectFit="contain"
          fluid={backgroundImage?.childImageSharp?.fluid}
          className="background-image"
        />
      )}
      {screens.xs && (
        <Img
          objectFit="contain"
          fluid={backgroundImage2?.childImageSharp?.fluid}
          className="background-image"
        />
      )} */}
      <div className="page container">
        <div className="wrapper">
          <div className="content">
            <div className="sub-title">Need veterinary advice?</div>
            <div className="sub-content">
              Phone A Vet is a phone app offering convenient, affordable,
              telehealth consultations with Australian registered veterinarians.
              Phone A Vet veterinarians are fully qualified and registered to
              practice in Australia. You can choose from veterinarians with
              expertise in companion animals, farm animals and wildlife.
            </div>
            <div className="img-download-group">
              <div className="img-download-item">
                <a
                  href="https://apps.apple.com/us/app/phone-a-vet/id1484582641?ls=1"
                  target="_blank"
                >
                  <img src={downAppStoreSvg} className="img-download-1" />
                </a>
              </div>
              <div className="img-download-item">
                <a
                  href="https://play.google.com/store/apps/details?id=vet.phoneavet.app&hl=en/"
                  target="_blank"
                >
                  <img src={downCHPlaySvg} className="img-download-2" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </IndexBannerStyles>
  );
};

export default IndexBanner;
