import styled from 'styled-components';
import theme from '../../configs/theme';
import bgForVets from '../../images/bg-forvets.png';

export const IndexForVetsStyles = styled.div`
  position: relative;
  padding-bottom: 40px;
  .background {
    background-image: url(${bgForVets});
    width: 100%;
    height: calc(100% - 214px);
    position: absolute;
    top: 214px;
    right: 0;
    bottom: 0;
  }
  .image {
    margin: auto;
    margin-left:0;
    @media only screen and (min-width: 768px) {
      margin: 0px;
    }
  }
  .feature-section {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: auto;
    padding: 108px 120px 99px 0px;
    .reverse {
      flex-direction: row-reverse;
      .feature-content {
        padding-left: 122px;
      }
      .image {
        padding-left: 177px;
      }
    }
    .feature-content {
      padding-left: 48px;
      .title {
        font-family: DM Sans;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #29abe2;
      }
      .sub-title {
        margin-bottom: 20px;
        margin-top: 20px;
        color: #242e33;
        letter-spacing: 1px;
        line-height: 50px;
      }

      .description {
        font-family: DM Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 36px;
        color: #36464c;
      }
    }
    img {
      object-fit: contain;
      width: 90%;
    }
    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
  }
  @media only screen and (max-width: 1024px) {
    .feature-section {
      padding: 48px 80px 0px 0px;
      .ant-col {
        width: 100%;
      }
      .ant-row {
        row-gap: none;
      }
      .image {
        margin-top: 60px;
        margin-bottom: 60px;
        width: 90% !important;
      }
    }
    .feature-content {
      margin-top: 10px;
      padding-left: 24px;
      .title {
        font-size: 16px;
        line-height: 32px;
        letter-spacing: 1px;
      }
      .sub-title {
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.25px;
      }
      .description {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.2px;
        // max-width: 323px;
      }
    }
    .reverse {
      feature-content {
        padding-left: 80px;
        margin-top: 0px;
      }
      .image {
        width: 125% !important;
        padding-left: 24px !important;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .feature-section {
      padding: 48px 24px 0px 0px;
      .feature-content {
        .title {
          font-size: 14px;
          line-height: 32px;
          letter-spacing: 1px;
        }
        .sub-title {
          font-size: 24px;
          font-weight: bold;
          line-height: 32px;
          letter-spacing: -0.25px;

          margin-bottom: 16px;
          margin-top: 16px;
        }
        .description {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.2px;

          margin-bottom: 40px;
        }
      }
      .reverse {
        .feature-content {
          padding-left: 48px;
        }
        .image {
          width: 100% !important;
        }
      }
    }
  }
    @media only screen and (max-width: 480px) {
    .feature-section {
      padding: 32px 0px 0px 0px;

      .ant-row {
        flex-direction: column-reverse;
      }
      .image {
        margin: 0px;
      }
      .feature-content {
        padding: 0px 24px;
        .title {
          font-size: 14px;
          line-height: 32px;
          letter-spacing: 1px;
        }
        .sub-title {
          font-size: 24px;
          font-weight: bold;
          line-height: 32px;
          letter-spacing: -0.25px;

          margin-bottom: 16px;
          margin-top: 16px;
          max-width: 325px;
        }
        .description {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.2px;

          margin-bottom: 40px;
        }
      }
      .reverse {
        padding: 0px 24px;
        .feature-content {
          padding-left: 0px;
        }
        .image {
          padding-left: 0px;
        }
      }
    }
  }
`;
