import styled from 'styled-components';

export const IndexBannerStyles = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  .background-image {
    width: 100%;
    height: 100%;
  }

  .ant-anchor-wrapper {
    background-color: transparent;
  }
  .page {
    padding-bottom: 90px;
    width: 100%;
    background-color: transparent;
    display: flex;
    .wrapper {
      max-width: 1200px;
      display: flex;
      flex-wrap: wrap;
      min-width: 50%;
      justify-content: space-around;
    }
    .content {
      flex: 1;
      padding-left: 80px;
      padding-top: 116px;
      display: flex;
      flex-direction: column;
      .sub-title {
        font-style: normal;
        font-weight: bold;
        font-size: 64px;
        line-height: 80px;
        color: #131415;
        max-width: 600px;
      }
      .sub-content {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 36px;
        max-width: 500px;
        padding: 34px 0px;
      }
      .img-download-group {
        .img-download-1 {
          width: 136px;
          height: 41px;
          background: #131415;
          border-radius: 5px;
        }
        @media only screen and (min-width: 567px) {
          display: flex;
          .img-download-item {
            margin-right: 12px;
          }
        }
        @media only screen and (max-width: 567px) {
          .img-download-item {
            margin-bottom: 12px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1280px) {
    .page {
      padding-bottom: 120px;
      .content {
        padding-top: 80px;
        padding-left: 40px;
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    .page {
      padding-bottom: 120px;
      .content {
        .sub-title {
          font-size: 34px;
          line-height: 43px;
          letter-spacing: -0.5px;
          max-width: 400px;
        }
        .sub-content {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.2px;
          padding: 24px 0px;
          max-width: 370px;
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .page {
      .content {
        padding-top: 60px;
        padding-left: 20px;
        .sub-title {
          font-size: 30px;
          line-height: 38px;
          letter-spacing: -0.5px;
          max-width: 370px;
        }
        .sub-content {
          font-size: 14px;
          line-height: 22px;
          letter-spacing: 0.2px;
          padding: 16px 0px;
        }
      }
    }
  }
  @media only screen and (max-width: 576px) {
    .page {
      .content {
        padding-left: 0px;
        padding-top: 85px;
        .sub-title {
          font-size: 32px;
          line-height: 40px;
          letter-spacing: -0.5px;
          max-width: 390px;
        }
        .sub-content {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.2px;
          padding: 16px 0px;
        }
      }
    }
  }
`;
