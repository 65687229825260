import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'antd';
import Img from 'gatsby-image';
import { IndexForVetsStyles } from './styles';

interface IndexForVetsProps {
  items: array;
  backgroundColor: string;
  isStarRight: boolean;
}

const IndexForVets: FC<IndexForVetsProps> = ({
  items,
  backgroundColor,
  isStartRight,
}) => {
  return (
    <IndexForVetsStyles style={{ backgroundColor }} id="forVeterinarians">
      <div className="background" />
      <div>
        {items?.map((e: any, index: number) => (
          <div key={`${String(index)}`} className="container feature-section">
            <Row
              className={`${
                (index % 2 === 0 && isStartRight) ||
                (index % 2 !== 0 && !isStartRight)
                  ? ''
                  : 'reverse'
              }`}
              type="flex"
              align="middle"
            >
              <Col md={12} sm={12}>
                <div
                  className="image"
                  style={{ width: index === 1 ? '100%' : '75%' }}
                >
                  <Img
                    objectFit="contain"
                    fluid={e?.image?.childImageSharp?.fluid}
                    style={{ maxWidth: 500 }}
                  />
                </div>
              </Col>
              <Col md={12} sm={12} className="feature-content">
                <div className="title">{e.title}</div>
                <div className="sub-title pav-title">{e.subTitle}</div>
                <div
                  className="description"
                  dangerouslySetInnerHTML={{ __html: e.description }}
                />
              </Col>
            </Row>
          </div>
        ))}
      </div>
    </IndexForVetsStyles>
  );
};

IndexForVets.defaultProps = {
  backgroundColor: 'white',
  isStartRight: true,
};

export default IndexForVets;
