import React, { useState } from 'react';
import { IndexContactWrapper } from './styles';
import { Row, Col, Button, Form, Input, notification } from 'antd';
import IconSuccess from 'images/icons/ic_success.svg';
import IconFailure from 'images/error.png';

const IndexContact: FC<IndexContactProps> = () => {
  const [form] = Form.useForm();

  const [isSended, setIsSended] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string>();
  const responseContent = {
    success: {
      icon: IconSuccess,
      title: 'Thank you',
      subTitle: 'We got your message and will get back with you as soon as we can.',
    },
    failure: {
      icon: IconFailure,
      title: 'ERROR! Failed to send your message',
      subTitle: 'Please try later or contact the Phone A Vet support team at support@phoneavet.com.au',
    },
  }
  
  function sendContact(params) {
    return fetch(`${process.env.GATSBY_API_URL}/contact-message`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(params),
    }).then(res => res.json());
  }

  const errorMessage = (
    <div>
      <div>Failed to send your message.</div>
      <div>
        Please try later or contact <br />
        the Phone A Vet support team at
        <b> support@phoneavet.com.au </b>
      </div>
    </div>
  );

  const openNotification = (message, desc, type, duration) => {
    const key = `open-${type}`;
    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={() => notification.close(key)}
      >
        OK
      </Button>
    );
    notification[type]({
      message: message,
      description: desc,
      duration: duration,
      btn,
      key,
    });
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);

    sendContact(values).then((res) => {
      setIsLoading(false);
      if (res.message !== 'accepted') {
        openNotification('FAIL', errorMessage, 'error', 0);
        setStatus('failure')
      } else {
        setStatus('success')
        openNotification(
          'Thank you',
          'We got your message and will get back with you as soon as we can',
          'success',
          0
        );
        form.resetFields();
      }
      setIsSended(true);
    });
  };

  return (
    <IndexContactWrapper id="contact">
      <div className="form-message">
        {!isSended && (
          <Row type="flex" align="middle">
            <Col sm={24} md={24} lg={24} xl={12}>
              <div className="content">
                <div className="t-bold-40px-50 mg-bottom-20 title-content">
                  Contact us
                </div>
                <div className="t-normal-20px-36 description-content">
                  Got questions? Complete the form below, we’d love to hear from
                  you!
                </div>
              </div>
            </Col>
            <Col sm={24} md={24} lg={24} xl={12}>
              <Form
                form={form}
                onFinish={handleSubmit}
                className="form-contact"
              >
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your name',
                        },
                      ]}
                    >
                      <Input placeholder="Name" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="email"
                      rules={[
                        { type: 'email', message: 'This is not valid E-mail!' },
                        {
                          required: true,
                          message: 'Please input your e-mail!',
                        },
                      ]}
                    >
                      <Input placeholder="Email" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item
                      name="message"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your message',
                        },
                      ]}
                    >
                      <Input.TextArea
                        placeholder="Message"
                        autoSize={{ minRows: 2 }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Button
                  loading={isLoading}
                  type="primary"
                  htmlType="submit"
                  className="btn-submit"
                >
                  Submit
                </Button>
              </Form>
            </Col>
          </Row>
        )}
        {isSended && (
          <div className="div-contact-success">
            <div className="ic-success">
              <img src={responseContent[`${status}`].icon} />
            </div>
            <div className="contact-title">{responseContent[`${status}`].title}</div>
            <div className="contact-description">
              {responseContent[`${status}`].subTitle}
            </div>
            <Button
              className="btn-submit send-another-contact"
              type="primary"
              onClick={() => setIsSended(false)}
            >
              Send us another request
            </Button>
          </div>
        )}
      </div>
    </IndexContactWrapper>
  );
};

export default IndexContact;
