import styled from 'styled-components';
import theme from 'configs/theme';

export const IndexContactWrapper = styled.div`
  background-color: #242e33;
  padding-right: 80px;
  padding-left: 80px;
  .content {
    padding-left:20px;
    .sub-title {
      font-family: DM Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #737c80;

      // padding-top: 64px;
      padding-bottom: 21px;
    }
    .main-title {
      padding: 21px 0px;
    }
    .main-content {
      padding: 21px 0px;
    }
    .sub-content {
      font-family: DM Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      color: #f7f7f7;

      padding: 21px 0px;

      li::marker {
        color: ${theme.palette.primary};
        font-size: 12px;
      }
      li {
        margin-bottom: 12px;
      }
    }
  }
  
  .btn {
    border: 1px solid ${theme.palette.primary};
    box-sizing: border-box;
    margin-top: 10px;
    color: ${theme.palette.primary};
    padding: 5px 15px;
    background-color: #242e33;
    filter: drop-shadow(0px 8px 16px rgba(41, 171, 226, 0.15));
    border-radius: 16px;
  }
  .form-message {
    background: #36464c;
    box-shadow: 0px 12px 36px #212629;
    border-radius: 8px;
    padding: 60px 120px;
    .content {
      margin-right: 40px;
    }
    .btn-submit {
      margin-top: 11px;
      background: ${theme.palette.primary};
      box-shadow: 0px 8px 16px rgba(41, 171, 226, 0.15);
      border-radius: 16px;
      width: 100%;
    }
    .div-contact-success {
      text-align: center;
      padding: 40px 0px;
      .contact-title {
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 50px;
        letter-spacing: 1px;
        color: #ffffff;

        padding: 20px 0px;
      }
      .contact-description {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 36px;
        color: #ffffff;
      }
      .send-another-contact {
        width: auto;
        padding-left: 30px;
        padding-right: 30px;
        margin-top: 20px;
      }
    }
  }

.row-contact-phone{
  align-items:center;
}
  @media only screen and (min-width: 1024px) {
    .content {
      .sub-title {
        // padding-top: 48px;
        padding-bottom: 10px;
      }
      .main-title {
        padding: 10px 0px;
      }
      .main-content {
        padding: 10px 0px;
      }
      .sub-content {
        padding: 10px 0px;
      }
    }
  }
  @media only screen and (max-width: 1200px) {
    .row-contact-phone{
      align-items: flex-end;
    }
    .gatsby-image-wrapper {
      width: 70%;
    }
    .btn-div {
      margin-bottom: 49px;
      .btn {
        margin-top: 0px;
      }
    }    
   } 
  @media only screen and (max-width: 1024px) {
    padding: 0px 48px 0px 48px;
    .ant-row {
      .ant-col {
        .gatsby-image-wrapper {
          width: 70%;
          margin-left: 20px;
          margin-top: 32px;
        }
      }
    }
    .content {
      max-width: 334px;
      .sub-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 32px;
        letter-spacing: 1px;
        padding: 0px;
      }
      .main-title {
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.25px;

        padding: 22px 0px;
      }

      .main-content {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.2px;

        padding-top: 0px;
        padding-bottom: 22px;
      }

      .sub-content {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.2px;

        padding-top: 0px;
        padding-bottom: 20px;
      }
    }
    .form-message {
      padding: 40px 40px;
      .form-contact{
        padding: 0px 59px 62px 59px;
      }
      .content {
        text-align: center;
        margin-right: auto;
        max-width: 100%;
        padding: 0px 59px 62px 59px;
        .title-content {
          font-weight: bold;
          font-size: 40px;
          line-height: 50px;
          letter-spacing: 1px;

          margin-bottom: 20px;
        }
        .description-content {
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.2px;
        }
      }
      .div-contact-success {
        text-align: center;
        padding: 40px 0px;
        .contact-title {
          font-weight: bold;
          font-size: 40px;
          line-height: 50px;
          letter-spacing: 1px;
        }
        .contact-description {
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.2px;
        }
      }
    }
    .footer {
      padding: 100px 40px 60px 40px;
      .left-content {
        margin-bottom: 66px;
        text-align: center;
        .title-left-content {
          font-weight: bold;
          font-size: 40px;
          line-height: 50px;
          letter-spacing: 1px;
          text-align: center;
        }
        .img-download-group {
          justify-content: center;
        }
      }
      .ant-row {
        .txt-copy {
          font-weight: normal;
          font-size: 14px;
          line-height: 30px;
          letter-spacing: 1px;

          margin-top: 14px;
        }

        .title-content {
          font-weight: 600;
          font-size: 18px;
          line-height: 30px;
          letter-spacing: 1px;
        }
        .description {
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.2px;

          padding: 10px 0px;
        }
      }
    }
    .row-contact-phone{
      align-items: flex-end;
    }
    .content{
      padding:0px;
    }   
    padding: 0px 48px 0px 48px;
    .ant-row {
      .ant-col {
        .gatsby-image-wrapper {
          margin-top: 50px;
        }
      }
    }
    .form-message {
      padding: 40px 40px;
      .form-contact{
        padding: 0px;
      }
    .content {
      padding: 30px;
      .sub-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 32px;
        letter-spacing: 1px;

        padding: 0px;
      }
      .main-title {
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.25px;

        padding: 22px 0px;
      }

      .main-content {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;

        padding-top: 0px;
        padding-bottom: 22px;
      }

      .sub-content {
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;

        padding-top: 0px;
        padding-bottom: 22px;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .form-message {
      padding: 40px 20px;
      .form-contact{
        padding: 20px;
      }
    .content {
      padding: 20px;
    }
  }
  @media only screen and (max-width: 576px) {
    
    .row-contact-phone{
      div{
        max-width:100%;
      }
    }
    .content{
      margin:0;
      max-width:100%;
    }
    padding: 0px 24px 32px 24px;
    .ant-row {
      flex-direction: column-reverse;
      .ant-col {
        width: 100%;
        .gatsby-image-wrapper {
          width: 72%;
          margin: auto;
        }
      }
    }
    .form-message {
      padding: 24px 0px;
      .ant-row {
        flex-direction: unset;
      }
      .form-contact{
        padding:20px;
      }
      .div-contact-success {
        text-align: center;
        padding: 40px 0px;
        .contact-title {
          font-weight: bold;
          font-size: 20px;
          line-height: 28px;
          text-align: center;

          padding: 10px 0px;
        }
        .contact-description {
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          letter-spacing: 0.1px;
        }
      }
    }
    .footer {
      padding: 40px 0px 0px 0px;
      .left-content {
        margin-bottom: 37px;
        text-align: center;
        .img-download-group {
          justify-content: center;
        }
      }
      .ant-row {
        flex-direction: unset;
        .txt-copy {
          font-weight: normal;
          font-size: 10px;
          line-height: 30px;
          letter-spacing: 1px;

          margin-top: 24px;
        }
        .title-left-content {
          font-weight: bold;
          font-size: 24px;
          line-height: 32px;
          text-align: center;
          letter-spacing: -0.25px;
        }
        .title-content {
          font-weight: 600;
          font-size: 18px;
          line-height: 30px;
          letter-spacing: 1px;

          padding-top: 14px;
        }
        .description {
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.2px;

          padding: 10px 0px;
        }
      }
    }
    .btn{
      width:100%;
    }
  }
`;
